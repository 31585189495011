@use 'sass:math';
@import 'guide.grid';

.vert {
	margin: 0 0 0.5em 0.2em;
}

.vert-always {
	writing-mode: vertical-rl;
	max-height: math.div($mobile - 40px, 2);
	margin: 0.5em 0 0;
}

.vert,
.vert-always {
	font-family: $p-font;
}

@media screen /* large screen */ and (min-width: $mobile) {
	.vert {
		writing-mode: vertical-rl;
		margin: 0.5em 0 0;
	}

	.vert,
	.vert-always {
		max-height: math.div($mobile - 40px, 1.55);
		font-size: 1.5em;
	}
}

@media screen /* small screen */ and (max-width: $mobile - 1) {
	.vert,
	.vert-always {
		font-size: 1.25em;
	}
}
