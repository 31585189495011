$blue: rgb(0.04 * 255, 0.32 * 255, 0.68 * 255);

* {
	box-sizing: border-box;
}

::selection {
	background-color: white;
	color: $blue;
}

p {
	color: $text-color;
}

body {
	background-color: $blue;
	margin: 0;
}

.wrapper {
	position: relative;
	min-height: 100vh;
	margin: auto;
}

@media screen /* large screen */ and (min-width: $mobile) {
	.wrapper {
		max-width: $mobile - 40px;
	}
}

@media screen /* small screen */ and (max-width: $mobile - 1) {
	.wrapper {
		max-width: calc(100% - 50px);
	}
}

main {
	margin: 2rem 0 5rem;
}

footer {
	color: $footer-color;
	text-align: center;
	position: absolute;
	bottom: 0;
	height: 1.5em;
	width: 100%;
	font-family: $footer-font;
}

img,
.logo svg,
.large-and-bold {
	animation: fadeIn 200ms ease-in-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
}
