@import 'gallery.grid';

$islands-bg: #0a52ad;
$desert-bg: #f1c673;
$forest-bg: #3b5d0d;
$arctic-bg: #1b3983;
$next-bg: $islands-bg;

.full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}

.islands,
.islands svg {
	background-color: $islands-bg;
}

.desert,
.desert svg {
	background-color: $desert-bg;
}

.forest,
.forest svg {
	background-color: $forest-bg;
}

.arctic,
.arctic svg {
	background-color: $arctic-bg;
}

.divider {
	custom-svg {
		position: relative;
		width: 100%;
		overflow: hidden;
		line-height: 0;
		bottom: -0.15em;
	}

	svg {
		position: relative;
		display: block;
		width: 100%;
		height: 25%;
		background-color: transparent !important;
	}

	&.to-desert {
		svg {
			fill: $desert-bg;
		}
	}

	&.to-forest svg {
		border-bottom: 2px solid $forest-bg;
		fill: $forest-bg;
		transform: rotateY(180deg);
	}

	&.to-arctic svg {
		border-bottom: 2px solid $arctic-bg;
		fill: $arctic-bg;
	}

	&.to-next svg {
		border-bottom: 2px solid $next-bg;
		fill: $next-bg;
		transform: rotateY(180deg);
	}
}
