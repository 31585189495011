@use 'vars';

.no-position {
	position: unset;
}

.travel {
	.item {
		width: 256px;
		height: 128px;
	}

	.g-tall-auto {
		height: 256px;
	}

	.arrow svg {
		animation: fadeIn 200ms ease-in-out;
		height: 256px;
		width: 256px;

		path {
			fill: $arrow-color;
		}
	}
}

@media screen /* large screen */ and (min-width: vars.$mobile) {
	.travel {
		margin: auto;
		max-width: 70%;
		grid-template-columns: 1fr 1fr;

		.one-up {
			transform: translate(-220px, -280px) !important;
		}

		.g-text p {
			position: absolute;
			transform: translateX(180px);
			font-size: 2em;
		}

		.arrow {
			z-index: -1;

			&.arrow-ul {
				position: relative;
				transform: translate(0, 0) rotate(45deg);
			}

			&.arrow-ur {
				transform: scaleX(-1) translate(0, 0) rotate(45deg);
			}

			&.arrow-dl {
				transform: translate(30px, -25px) rotate(-78deg);
			}
		}
	}
}

@media screen /* small screen */ and (max-width: vars.$mobile - 1) {
	.travel {
		overflow: hidden;
		grid-template-columns: 1fr;

		.one-up {
			transform: translate(180px, -250px) !important;
		}

		.g-text p {
			position: absolute;
			transform: translateX(20px);
			font-size: 2em;
		}

		&.full-width-mobile {
			width: 100vw;
			position: relative;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
		}

		.first-special {
			margin: 40px 10% 0 0;
		}

		.margin-when-small {
			margin-top: 10%;
			height: 256px - 64;
		}

		.arrow-dl {
			margin-top: 135px;
		}

		.empty {
			height: 0 !important;
		}

		.arrow {
			z-index: -1;

			&.arrow-ul {
				position: relative;
				transform: translate(100px, 0) rotate(70deg);
			}

			&.arrow-ur {
				transform: scaleX(-1) translate(100px, 0) rotate(70deg);
			}

			&.arrow-dl {
				transform: translate(-110px, 0) rotate(-110deg);
			}
		}
	}
}
