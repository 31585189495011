.grid {
	display: grid;

	.item {
		width: fit-content;
		justify-self: center;
	}

	.g-wide-auto {
		grid-area: auto / auto / span 1 / span 2;
	}

	.g-tall-auto {
		grid-area: auto / auto / span 2 / span 1;
	}

	.g-two-auto {
		grid-area: auto / auto / span 2 / span 2;
	}

	.g-no-1-template {
		grid-area: no-1;
	}

	.g-no-2-template {
		grid-area: no-2;
	}

	.g-two-template {
		grid-area: two;
	}

	.g-wide-template {
		grid-area: wide;
	}

	.g-tall-template {
		grid-area: tall;
	}

	.g-tall-1-template {
		grid-area: tall-1;
	}

	.g-tall-2-template {
		grid-area: tall-2;
	}

	.g-tall-3-template {
		grid-area: tall-3;
	}
}
