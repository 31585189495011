.logo-container {
	position: relative;
	padding-top: 0.5em;
}

@media screen /* large screen */ and (min-width: $mobile) {
	.logo-container[class*='screen'] {
		height: 100px;
	}

	.logo-container[class*='mobile'] {
		display: none;
	}
}

@media screen /* small screen */ and (max-width: $mobile - 1) {
	.logo-container[class*='screen'] {
		display: none;
	}

	.logo-container[class*='mobile'] {
		display: block;
		width: 100px;
		margin: auto;
	}
}

custom-svg.logo {
	svg {
		width: 100px;
		height: 100px;
	}

	path {
		fill: hsl(0, 0%, 100%);
	}
}
