$_text: hsl(0, 0%, 100%);
$_text-2: hsl(221, 100%, 85%);

$nav-color: $_text;
$text-color: $_text;
$nav-color-here: $_text;
$tagline-color: $_text-2;
$footer-color: $_text;
$arrow-color: $_text-2;

$mobile: 768px;
$tablet: 992px;

$_font: 'Noto Serif JP', 'Noto Serif', 'Times New Roman', Times, serif;

$nav-font: $_font;
$big-font: $_font;
$p-font: $_font;
$footer-font: $_font;
