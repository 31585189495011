@media screen /* large screen */ and (min-width: $mobile) {
	#tagline,
	.large-and-bold {
		font-size: 4.5rem;
		letter-spacing: -6px;
		margin-top: 0;
	}
}

@media screen /* small screen */ and (max-width: $mobile - 1) {
	#tagline,
	.large-and-bold {
		font-size: 3rem;
		letter-spacing: -3px;
	}
}

#tagline,
.large-and-bold {
	color: $tagline-color;

	span {
		font-weight: 700;
		font-family: $big-font;
	}
}

.center {
	margin: auto;
	text-align: center;
}

h1 span {
	display: inline-block;
}
